<template>
  <div class="container is-fluid" style="display: relative">
    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    <div class="columns mt-1">
      <div class="column is-7">
        <b-field>
          <b-taginput
            v-model="selectedCategories"
            :data="filteredCategories"
            :allow-duplicates="false"
            autocomplete
            type="is-dark"
            :open-on-focus="true"
            @typing="getFilteredCategories"
          >
          </b-taginput>
        </b-field>
        <b-button type="is-primary" @click="resetCategories">{{
          $t('omw-marketing-reset-categories')
        }}</b-button>
        <span class="ml-2 has-text-weight-semibold">
          {{ $t('marketing-count-campaigns-in-filter') }}:
          {{ filteredCampaigns.length }}
        </span>
        <div class="field mt-4">
          <div class="block">
            <b-radio v-model="whatToDisplay" native-value="all">
              {{ $t('omw-marketing-show-all') }}
            </b-radio>
            <b-radio v-model="whatToDisplay" native-value="published">
              {{ $t('omw-marketing-show-published') }}
            </b-radio>
            <b-radio v-model="whatToDisplay" native-value="unpublished">
              {{ $t('omw-marketing-show-unpublished') }}
            </b-radio>
            <b-radio v-model="whatToDisplay" native-value="default">
              {{ $t('omw-marketing-show-default') }}
            </b-radio>
          </div>
        </div>
      </div>
      <div class="column">
        <b-field :label="$t('omw-marketing-include-campaigns-no-category')">
          <b-switch
            v-model="includeCampaignsWithNoCategory"
            :true-value="true"
            :no-value="false"
            >{{
              includeCampaignsWithNoCategory
                ? $t('general-yes')
                : $t('general-no')
            }}</b-switch
          >
        </b-field>
        <b-field :label="$t('omw-marketing-include-archived')">
          <b-switch
            v-model="includeArchived"
            :true-value="true"
            :no-value="false"
            >{{
              includeArchived ? $t('general-yes') : $t('general-no')
            }}</b-switch
          >
        </b-field>
      </div>
      <div
        v-if="!hasDefault || !hasPublished"
        class="column has-text-danger is-size-6 has-text-weight-semibold"
      >
        <b-notification
          v-if="!hasDefault"
          type="is-warning"
          has-icon
          role="alert"
        >
          {{ $t('omw-marketing-no-default-warning') }}
        </b-notification>
        <b-notification
          v-if="!hasPublished"
          type="is-warning"
          has-icon
          role="alert"
        >
          {{ $t('omw-marketing-no-published-warning') }}
        </b-notification>
      </div>
    </div>
    <div
      v-if="!campaigns || campaigns.length === 0"
      class="is-size-4"
      style="text-align: center; padding-top: 1em"
    >
      {{ $t('omw-marketing-no-campaigns-warning') }}
    </div>
    <div v-else class="has-text-centered">
      <b-carousel
        :indicator-inside="false"
        :autoplay="false"
        :indicator-custom="true"
        indicator-custom-size="is-small"
        indicator-position="is-top"
      >
        <b-carousel-item v-for="(campaign, i) in filteredCampaigns" :key="i">
          <div
            class="card-box mt-2 box"
            :style="archivedStyle(campaign.isArchived)"
          >
            <span v-if="campaign.isDefault" :style="defaultBadgeStyle">{{
              campaign.isDefault ? $t('omw-marketing-default') : null
            }}</span>
            <span v-else-if="campaign.isArchived" :style="absentBadgeStyle">{{
              $t('omw-marketing-archived')
            }}</span>

            <div>
              <span class="is-italic">{{
                $t('omw-marketing-clickthrough-count')
              }}</span>
              <p class="has-text-weight-semibold has-text-primary">
                {{ showClicks(campaign.name) }}
              </p>
              <figure style="display: relative">
                <img
                  :src="campaign.imageUrl"
                  style="width: auto; max-height: 300px"
                  class="shadow"
                />
              </figure>
            </div>
            <div class="card-content has-text-centered">
              <b-tooltip :label="campaign.name" position="is-bottom">
                <p class="truncate is-size-5 pt-5">
                  {{ campaign.name }}
                </p>
              </b-tooltip>
              <b-field v-if="campaign.clickUrl">
                <a :href="campaign.clickUrl" target="_new">
                  <b-tooltip :label="campaign.clickUrl">
                    <p class="truncate is-size-6">
                      {{ campaign.clickUrl }}
                    </p>
                  </b-tooltip>
                </a>
              </b-field>
            </div>
            <div style="display: inline-block">
              <b-taglist class="has-text-centered">
                <b-tag
                  v-for="tag in campaign.categories"
                  :key="tag"
                  type="is-light"
                >
                  {{ tag }}
                </b-tag>
              </b-taglist>
              <div class="buttons">
                <b-button
                  v-if="campaign.isPublished"
                  type="is-primary"
                  :loading="unpublishLoading"
                  @click="unpublishCampaign(campaign.name)"
                >
                  {{ $t('omw-marketing-unpublish') }}
                </b-button>
                <b-button
                  v-if="!campaign.isPublished && !campaign.isArchived"
                  type="is-danger"
                  :loading="archiveLoading"
                  @click="archiveCampaign(campaign.name, true)"
                >
                  {{ $t('omw-marketing-archive') }}
                </b-button>
                <b-button
                  v-if="campaign.isArchived"
                  type="is-dark"
                  :loading="archiveLoading"
                  @click="archiveCampaign(campaign.name, false)"
                >
                  {{ $t('omw-marketing-unarchive') }}
                </b-button>
                <b-button
                  v-if="!campaign.isPublished"
                  type="is-info"
                  :loading="publishLoading"
                  @click="publishCampaign(campaign.name)"
                >
                  {{ $t('omw-marketing-publish') }}
                </b-button>
                <b-button type="is-primary" @click="openUpdateForm(campaign)">
                  {{ $t('omw-marketing-update-metadata') }}
                </b-button>
                <b-button type="is-success" @click="showPreview(campaign)">
                  {{ $t('omw-marketing-show-preview') }}
                </b-button>
                <b-button
                  type="is-success"
                  :loading="campaignLoadingStates[i]"
                  @click="showStats(i)"
                >
                  {{ $t('omw-marketing-clickthrough-stats') }}
                </b-button>
              </div>
            </div>
          </div>
        </b-carousel-item>
        <template #indicators="props">
          <span class="al image">
            <span v-if="getIsArchived(props.i)" :style="archivedBadgeStyle">{{
              $t('omw-marketing-archived')
            }}</span>
            <img :src="getImgUrl(props.i)" :title="props.i" />
            <p>{{ getTitle(props.i) }}</p>
          </span>
        </template>
      </b-carousel>
      <p v-if="error" class="has-text-danger">
        {{ error }}
      </p>
    </div>
    <div class="columns is-vcentered">
      <div class="column">
        <div class="buttons is-centered">
          <b-button
            icon-left="cloud-upload-alt"
            type="is-dark"
            class="is-medium"
            @click="openUploadForm()"
          >
            {{ $t('omw-marketing-create-campaign') }}
          </b-button>
          <b-button
            icon-left="mouse-alt"
            type="is-primary"
            class="is-medium"
            @click="getNewClickData()"
          >
            {{ $t('omw-marketing-refresh-data') }}
          </b-button>
          <b-button
            icon-left="sync-alt"
            type="is-primary"
            class="is-medium"
            @click="getCampaigns(includeArchived)"
          >
            {{ $t('omw-marketing-refresh') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import styles from '@/sass/portal.scss';

import {
  publishCampaign,
  unpublishCampaign,
  archiveCampaign,
  unarchiveCampaign,
  restrictCampaign,
  makeCampaignDefault,
} from '@/services/marketing/index';

import CampaignCreate from './CampaignCreate';
import CampaignUpdate from './CampaignUpdate';
import CampaignClicks from './CampaignClicks';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ManageCampaigns',
  data() {
    return {
      value: null,
      error: null,
      whatToDisplay: 'all',
      refreshInterval: undefined,
      isLoading: false,
      uploadModal: undefined,
      updateModal: undefined,
      publishLoading: false,
      unpublishLoading: false,
      archiveLoading: false,
      makeDefaultLoading: false,
      makeRestrictedLoading: false,
      selectedCategories: [],
      filteredCategories: [],
      includeCampaignsWithNoCategory: true,
      includeArchived: false,
      campaignLoadingStates: [],
    };
  },
  computed: {
    ...mapGetters('storeMarketing', ['campaigns', 'campaignClicks']),
    defaultBadgeStyle() {
      return {
        backgroundColor: styles.success,
        color: 'white',
        padding: '0 4px',
        borderRadius: '5px',
      };
    },
    archivedBadgeStyle() {
      return {
        backgroundColor: styles.dark,
        color: 'white',
        padding: '0 4px',
        borderRadius: '5px',
      };
    },
    absentBadgeStyle() {
      return {
        minHeight: '2em',
        color: 'white',
      };
    },
    displayCampaigns() {
      let baseFilterApplied = [];
      if (this.whatToDisplay === 'published') {
        return this.campaigns.filter((campaign) => campaign.isPublished);
      } else if (this.whatToDisplay === 'all') {
        return this.campaigns;
      } else if (this.whatToDisplay === 'unpublished') {
        return this.campaigns.filter((campaign) => !campaign.isPublished);
      } else if (this.whatToDisplay === 'default') {
        return this.campaigns.filter((campaign) => campaign.isDefault);
      } else {
        return null;
      }
    },
    filteredCampaigns() {
      return this.displayCampaigns.filter((campaign) => {
        if (
          campaign?.categories?.length === 0 &&
          this.includeCampaignsWithNoCategory
        ) {
          return true;
        }
        if (
          this.selectedCategories.length === 0 &&
          campaign?.categories?.length > 0
        ) {
          return false; // If we have no categories selected but this campaign has categories, we don't want to show it
        }
        let found = false;
        for (let i = 0; i < campaign?.categories?.length; i++) {
          if (this.selectedCategories.includes(campaign?.categories[i])) {
            found = true;
            break;
          }
        }
        return found;
      });
    },
    availableCategories() {
      return this.getAllCategories();
    },
    hasDefault() {
      const filteredbyDefault = this.campaigns.filter((campaign) => {
        return campaign.isDefault && campaign.isPublished;
      });
      return filteredbyDefault.length !== 0;
    },
    hasPublished() {
      const filteredbyPublished = this.campaigns.filter(
        (campaign) => campaign.isPublished === true,
      );
      return filteredbyPublished.length !== 0;
    },
  },
  watch: {
    campaigns: {
      immediate: true,
      handler(newVal) {
        this.getAllCategories();
        if (newVal) {
          this.campaignLoadingStates = newVal.map((campaign) => {
            return false;
          });
        }
      },
    },
    includeArchived: {
      immediate: true,
      async handler(newVal) {
        try {
          this.isLoading = true;
          await this.getCampaigns(newVal);
          await this.getNewClickData();
        } catch (err) {
          this.error = err.message;
        } finally {
          this.isLoading = false;
        }
      },
    },
  },
  mounted() {
    this.selectedCategories = this.getAllCategories();
  },
  methods: {
    ...mapActions('storeMarketing', [
      'setClicks',
      'getMarketingCampaigns',
      'getClickDataForCampaigns',
      'getClickDataDetail',
    ]),
    getImgUrl(index) {
      return this?.filteredCampaigns?.[index]?.imageUrl;
    },
    getTitle(index) {
      return this?.filteredCampaigns?.[index]?.name;
    },
    getIsArchived(index) {
      return this?.filteredCampaigns?.[index]?.isArchived;
    },
    async getCampaigns(includeArchived) {
      await this.getMarketingCampaigns(includeArchived);
      this.resetCategories();
    },
    archivedStyle(isArchived) {
      return {
        backgroundColor: isArchived ? 'lightgray' : undefined,
        opacity: isArchived ? '0.7' : 1,
      };
    },
    getFilteredCategories(text) {
      this.filteredCategories = this.availableCategories.filter((option) => {
        return option.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    getAllCategories() {
      if (!this.displayCampaigns) return [];
      const categorySet = new Set();
      this.displayCampaigns.forEach((campaign) => {
        if (campaign?.categories?.length > 0) {
          campaign.categories.forEach((category) => {
            categorySet.add(category);
          });
        }
      });
      return Array.from(categorySet);
    },
    resetCategories() {
      this.selectedCategories = this.getAllCategories();
    },
    async getNewClickData() {
      await this.getClickDataForCampaigns();
    },
    showClicks(campaignName) {
      if (!this.campaignClicks) {
        return 0;
      }
      const imageSpecificClicks = this.campaignClicks[campaignName];
      if (imageSpecificClicks) {
        return imageSpecificClicks.length;
      } else {
        return 0;
      }
    },
    async publishCampaign(campaignName) {
      this.error = null;
      try {
        this.publishLoading = true;
        await publishCampaign(campaignName);
        this.getCampaigns(this.includeArchived);
      } catch (error) {
        this.error = error;
      } finally {
        this.publishLoading = false;
      }
    },
    async unpublishCampaign(campaignName) {
      this.error = null;
      try {
        this.unpublishLoading = true;
        await unpublishCampaign(campaignName);
        this.getCampaigns(this.includeArchived);
      } catch (error) {
        this.error = error;
      } finally {
        this.unpublishLoading = false;
      }
    },
    async makeCampaignDefault(campaignName) {
      this.error = null;
      try {
        await makeCampaignDefault(campaignName);
        this.getCampaigns(this.includeArchived);
      } catch (error) {
        this.error = error;
      }
    },
    async restrictCampaign(campaignName) {
      this.error = null;
      try {
        await restrictCampaign(campaignName);
        this.getCampaigns(this.includeArchived);
      } catch (error) {
        this.error = error;
      }
    },
    async archiveCampaign(campaignName, archive) {
      this.error = null;
      try {
        this.archiveLoading = true;
        archive
          ? await archiveCampaign(campaignName)
          : await unarchiveCampaign(campaignName);
        this.getCampaigns(this.includeArchived);
      } catch (error) {
        this.error = error;
      } finally {
        this.archiveLoading = false;
      }
    },
    openUploadForm() {
      this.uploadModal = this.$buefy.modal.open({
        parent: this,
        component: CampaignCreate,
        hasModalCard: true,
        events: {
          'upload-complete': () => {
            this.uploadModal.close();
            this.getCampaigns(this.includeArchived);
          },
          loading: (loading) => {
            this.isLoading = loading;
          },
        },
      });
    },
    openUpdateForm(campaign) {
      this.updateModal = this.$buefy.modal.open({
        parent: this,
        component: CampaignUpdate,
        hasModalCard: true,
        props: {
          campaign,
        },
        events: {
          'update-complete': () => {
            this.updateModal.close();
            this.getCampaigns(this.includeArchived);
          },
          loading: (loading) => {
            this.isLoading = loading;
          },
        },
      });
    },
    showPreview(campaign) {
      let url = `${
        this.$configData.marketing.frontendUrlForPreview
      }&marketingPreviewUrl=${encodeURIComponent(campaign.imageUrl)}`;
      if (campaign.clickUrl) {
        url += `&clickUrl=${encodeURIComponent(campaign.clickUrl)}`;
      }
      window.open(url);
    },
    async showStats(index) {
      const campaign = this.campaigns[index];
      try {
        this.campaignLoadingStates[index] = true;
        this.error = null;
        await this.getClickDataDetail(campaign.name);
        this.$buefy.modal.open({
          parent: this,
          component: CampaignClicks,
          hasModalCard: true,
          props: {
            campaignName: campaign.name,
          },
        });
      } catch (err) {
        this.error = err.message;
      } finally {
        this.campaignLoadingStates[index] = false;
      }
    },
  },
});
</script>

<style scoped>
.card-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.truncate {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.is-active .al img {
  border: 1px solid #fff;
  filter: grayscale(0%);
}
.al img {
  border: 1px solid transparent;
  filter: grayscale(100%);
  max-width: 50vw;
  margin: auto;
}
</style>
