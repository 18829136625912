<template>
  <span>
    <!-- eslint-disable vue/require-v-for-key -->
    <div v-for="click in appointmentClickDetail">
      <p>
        {{ $t('omw-marketing-appointment-status') }}:
        {{ click.apptStatus }}
      </p>
      <p>
        {{ $t('omw-marketing-click-on-date') }}:
        {{ formatDate(click.clickedDate) }}
      </p>
      <hr />
    </div>
  </span>
</template>

<script>
export default {
  props: {
    appointmentClickDetail: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    tz() {
      return this.$moment.tz.guess(true);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      return this.$d(this.$moment(date).tz(this.tz).toDate(), 'long');
    },
  },
};
</script>

<style></style>
