import Papa from 'papaparse';
import { DateTime } from 'luxon';
import createObjectForCsv from './createObjectForCsv';
import saveFile from 'save-as-file';

export function exportCsv(prefix, infix, extension, columns, data) {
  const fields = [];
  // const dataForCsv = createObjectForCsv(data);
  columns.forEach((heading) => {
    fields.push(heading.field || heading);
  });
  const keys = Object.keys(fields);
  const dataObj = {
    fields,
    data,
  };
  const csv = Papa.unparse(dataObj, {
    quoteChar: '"',
  });
  let filename;

  const now = DateTime.now();
  filename = `${prefix}${infix ? '-' + infix : ''}-${now.toFormat(
    'yyyy-MM-dd',
  )}.${extension}`;

  const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
  saveFile(blob, filename);
}

export function exportJson(prefix, infix, data, prettify = false) {
  const now = DateTime.now();
  const filename = `${prefix}${infix ? '-' + infix : ''}-${now.toFormat(
    'yyyy-MM-dd',
  )}.json`;

  const outputData = prettify
    ? JSON.stringify(data, null, 2)
    : JSON.stringify(data);
  const blob = new Blob([outputData], {
    type: 'application/json;charset=utf-8',
  });
  saveFile(blob, filename);
}
