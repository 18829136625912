<template>
  <validation-observer v-slot="{ invalid }" ref="valobs">
    <div class="modal-card" style="width: 35vw">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t('omw-marketing-create-campaign-title') }}
        </p>
      </header>

      <section class="modal-card-body">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          :name="$t('omw-marketing-image-name')"
        >
          <b-field label="Name">
            <b-input
              v-model="name"
              type="text"
              :placeholder="$t('omw-marketing-image-name')"
            >
            </b-input>
          </b-field>
          <span class="help is-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          :name="$t('omw-marketing-clickthrough-url')"
          rules="httpRegex"
        >
          <b-field :label="$t('omw-marketing-clickthrough-url')">
            <b-input
              v-model="clickUrl"
              type="text"
              :placeholder="$t('omw-marketing-clickthrough')"
            >
            </b-input>
          </b-field>
          <span class="help is-danger">
            {{ errors[0] }}
          </span>
        </validation-provider>
        <b-field :label="$t('omw-marketing-add-categories')">
          <b-taginput
            v-model="categories"
            ellipsis
            icon="tag"
            type="is-dark"
            placeholder="Add a category"
          >
          </b-taginput>
        </b-field>
        <b-field :label="$t('omw-marketing-image-default')">
          <b-switch v-model="isDefault" :true-value="true" :false-value="false">
            {{ isDefault ? $t('general-yes') : $t('general-no') }}
          </b-switch>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-field class="file is-primary buttons">
          <b-upload
            v-model="file"
            class="file-label is-primary"
            accept="image/png,image/jpeg"
            :disabled="invalid"
            @input="newCampaign"
          >
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">{{
                $t('omw-marketing-upload-label')
              }}</span>
            </span>
          </b-upload>
          <span v-if="file" class="file-name">
            {{ file.name }}
          </span>
          <b-button
            style="margin-left: 2em"
            type="is-black"
            @click="$parent.close()"
          >
            {{ $t('omw-marketing-close') }}
          </b-button>
        </b-field>
      </footer>
    </div>
  </validation-observer>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { createCampaign } from '@/services/marketing/index';

import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';

setInteractionMode('aggressive');

export default defineComponent({
  name: 'CreateCampaign',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      file: null,
      httpRegex: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
      clickUrl: null,
      name: null,
      categories: [],
      isDefault: false,
    };
  },
  computed: {
    requestHeader() {
      const headerObj = {
        'Content-Type': 'multipart/form-data',
      };
      return headerObj;
    },
  },
  beforeCreate() {
    extend('httpRegex', {
      message: (field) => {
        let msg = field;
        msg += this.$t('omw-marketing-url-validation-msg');
        return msg;
      },
      validate: (value) => {
        const httpRegex = RegExp(this.httpRegex);
        return httpRegex.test(value);
      },
    });
  },
  methods: {
    async newCampaign(file) {
      try {
        this.$emit('loading', true);
        await createCampaign(
          {
            name: this.name,
            isPublished: false,
            clickUrl: this.clickUrl,
            categories: this.categories,
            isDefault: this.isDefault,
            isArchived: false,
          },
          file,
        );
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t(`Image uploaded successfully`),
          position: 'is-top',
          type: 'is-info',
        });
      } catch (err) {
        this.error = err;
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t(`Error uploading image: `) + err,
          position: 'is-top',
          type: 'is-danger',
        });
      } finally {
        this.$emit('loading', false);
        this.$emit('upload-complete');
      }
    },
  },
});
</script>
